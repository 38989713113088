.commitment {
    height: 100vh;
    overflow-y: hidden;
}

.commitment_head_div {
    padding-top: 5rem;
}

.commitment_heading {
    justify-self: center;
    padding: 2rem;
    padding-top: 6rem;
    font-size: 3.5rem;
    font-weight: 600;
    color: #c73d3d;
    font-family: "Roboto Condensed", sans-serif !important;
}

.commitment_content {
    padding: 5rem;
    font-size: 1.5rem;
    font-weight: 500;
}

.commitment_image {
    height: 15rem;
    padding-top: 3rem;
}

@keyframes comSlideIn {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

.com-slide-text {
    font-size: 3.5rem;
    font-weight: 600;
    display: inline-block;
    animation: comSlideIn 1s ease-out forwards;
    position: relative;
}
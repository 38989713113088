.expertise {
    padding: 1rem;
    height: 100vh;
    overflow-y: hidden;
}

.expertise_heading {
    justify-self: center;
    padding-top: 7rem;
    font-size: 3.5rem;
    font-weight: 600;
    height: fit-content;
    color: #c73d3d;
    font-family: "Roboto Condensed", sans-serif !important;

}

.expertise_content {
    padding: 5rem;
    font-size: 1.6rem;
    font-weight: 500;
}

.img_right1 {
    height: 15rem;
}

.img_right2 {
    height: 20rem;
}

.img_left2 {
    margin-top: 5rem;
    height: 20rem;
}

@keyframes extSlideIn {
    from {
        transform: translateX(0%);
        opacity: 0;
    }

    to {
        transform: translateX(120%);
        opacity: 1;
    }
}

.ext-slide-text {
    font-size: 3.5rem;
    font-weight: 600;
    display: inline-block;
    animation: extSlideIn 1.4s ease-out forwards;
    position: relative;
}
.page {
    background: #ced8d9;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #63a487, #ced8d9);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #63a487, #ced8d9);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    color: black;
}

@keyframes FadeInOut {
    from {
        transform: translateX(0%);
        opacity: 0;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

.fade-in-out {
    display: inline-block;
    animation: FadeInOut 2s ease-out forwards;
}
.associates {
    height: 100%;
}

.associate_heading {
    justify-self: center;
    padding-top: 5rem;
    font-size: 3.5rem;
    font-weight: 600;
    color: #c73d3d;
    font-family: "Roboto Condensed", sans-serif !important;
    /* height: fit-content; */
}

.associates_content {
    padding-top: 5rem;
    padding-left: 12rem;
    padding-right: 4rem;
    padding-bottom: 6rem;
    font-size: 1.5rem;
    font-weight: 500;
}

.associates_image {
    height: 30rem;
}

ul {
    list-style-type: circle;
}

@keyframes assoSlideIn {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

.asso-slide-text {
    font-size: 3.5rem;
    font-weight: 600;
    display: inline-block;
    animation: assoSlideIn 1.4s ease-out forwards;
    position: relative;
}
.whatwedo {
    padding: 9rem;
    font-size: 1.4rem;
    height: 100%;
    padding-bottom: 5.5rem;
}

.whatwedo_heading {
    justify-self: center;
    padding-top: 7rem;
    height: fit-content;
}

.provide {
    color: #375446;
    font-size: 3rem;
    font-weight: 600;
    font-family: "Roboto Condensed", sans-serif !important;
}

.left_div {
    width: 50%;
}

.left_panel {
    color: #c73d3d;
    width: 70vh;
    font-size: 3.5rem;
    font-weight: bolder;
    line-height: 200%;
    font-family: "Roboto Condensed", sans-serif !important;
}

.whatwedo_img {
    height: 30rem;
    width: 100%;
}

.whatwedo_points {
    /* overflow-y: scroll; */
    height: 30rem;
}

@keyframes typing {
    from {
        width: 0%;
    }

    to {
        width: 50%;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

.typing-effect {
    font-family: monospace;
    font-size: 2rem;
    overflow: hidden;
    /* border-right: 2px solid #000; */
    white-space: nowrap;
    /* width: 0; */
    animation: typing 4s steps(30, end), blink 0.5s step-end infinite;
}
.experience {
    height: 100%;
    padding-bottom: 7.5rem;
}

.experience_heading {
    padding-top: 7rem;
    font-size: 3.5rem;
    font-weight: 600;
    height: fit-content;
    color: #c73d3d;
    font-family: "Roboto Condensed", sans-serif !important;
}

.experience_info {
    padding-right: 8rem;
    padding-top: 6rem;
    font-size: 1.6rem;
    font-weight: 500;
}

.experience_points {
    width: 70%;
}

@keyframes expSlideIn {
    from {
        transform: translateX(0%);
        opacity: 0;
    }

    to {
        transform: translateX(50%);
        opacity: 1;
    }
}

.exp-slide-text {
    font-size: 3.5rem;
    font-weight: 600;
    display: inline-block;
    animation: expSlideIn 1.4s ease-out forwards;
    position: relative;
}
.contact {
    padding: 2rem;
    padding-bottom: 7rem;
    height: 100vh;
    overflow-y: scroll !important;
}

.contact_heading {
    justify-self: center;
    padding: 2rem;
    padding-top: 6rem;
    font-size: 3.5rem;
    font-weight: 600;
    color: #c73d3d;
    font-family: "Roboto Condensed", sans-serif !important;
}

.key_heading {
    font-size: 3.5rem;
    font-weight: 600;
    color: #c73d3d;
    font-family: "Roboto Condensed", sans-serif !important;
}

.contact_info {
    font-size: 1.15rem;
    justify-self: center;
}

.card_title {
    font-size: 2rem !important;
}

.card_body {
    background-color: #375446 !important;
    color: white !important;
    width: 30rem;
}

.phone {
    height: 20rem;
}

@keyframes contSlideIn {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

.cont-slide-text {
    font-size: 3.5rem;
    font-weight: 600;
    display: inline-block;
    animation: contSlideIn 1.4s ease-out forwards;
    position: relative;
}